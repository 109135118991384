@charset "utf-8";
@import "../../node_modules/bulma/sass/utilities/_all";
@import "vars";

.vanta-canvas {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  top: unset !important;
}

.title{
  color: #b7b7b7;
}

.subtitle {
  color: #9f9d9d;
}

.project{
  display: inline-block;
  margin: 1em 2em;
  position: relative;


  &.hover{
    transform: scale(1.02);
    cursor: pointer;
    .background-img {
      filter: grayscale(0.5);
      opacity: 1;
    }
  }
  transition: all 0.1s ease-out;
}

.is-fluid {
  padding: 0 !important;
}

.project-heading {
  border-radius: 6px 6px 0 0;
  color: #363636;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  background: linear-gradient(to right, #0a0a0a, #777);
}

.project-content {
  padding: 0;
  border-radius: 0 0 6px 6px;
  .background-img {
    border-radius: 0 0 6px 6px;
    opacity: 0.8;
    z-index: 0;
    filter: grayscale(1);
    transition: all 0.1s ease-out;
  }
  .project-img {
    z-index: 1;
  }
}

.facsa-logo {
  position: absolute;
  width: 175px;
  top: -30px;
  right: -50px;
  z-index: 0;
  clip-path: polygon(77.37% 77.89%, 11.14% 77.06%, 15.35% 31.05%, 48.67% 1.66%, 97.34% 12.3%);
}

.ltsv-logo {
  position: absolute;
  width: 150px;
  top: -30px;
  right: -45px;
  z-index: 0;
}

.lcdm-logo {
  position: absolute;
  width: 150px;
  top: -45px;
  right: -50px;
  z-index: 0;
  clip-path: polygon(91.75% 96.19%, 0.68% 99.28%, 29.08% 21.9%, 79.04% 12.3%);
}

.kitron-logo {
  position: absolute;
  width: 150px;
  top: -40px;
  right: -30px;
  z-index: 0;
  clip-path:polygon(82.67% 99.18%, 5.30% 86.86%, 10.21% 41.17%, 38% 13.93%, 94.67% 30.51%);
}

.ikinsu-logo {
  position: absolute;
  width: 175px;
  top: -70px;
  right: -40px;
  z-index: 0;
  clip-path: polygon(74.57% 99.07%, 16.19% 91.73%, 17.85% 37.51%, 49.16% 21.23%, 88.37% 29.14%, 95.53% 57.87%);
}

@include until($tablet) {
  .flex-column {
    flex-direction: column;
  }
}

.project-item {
  text-align: left;
}

.glide__bullets {
  margin: 1em auto;
}

.bullet-container {
  display: flex;
}

.glide__bullet {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: $dominante;
  border: 0;
  cursor: pointer;
}
.glide__bullet--active { background: #fff; }

.glide__slides {
  overflow: unset;
}

.glide__track {
  overflow: unset;
}

.navbar {
  z-index: 0;
}